import React from "react"
import { ID } from "@framework/types/types"
import throttle from "lodash/throttle"

const emptyArray: any[] = []

type AdGroupEntity = { AdGroupId: number } | { AdgroupId: number }

export const useFilteredAdGroupChild = <T extends AdGroupEntity>(config: {
  collection: T[] | null
  adGroups: ID[]
}) => {
  const { collection, adGroups } = config

  const [inProgress, setInProgress] = React.useState(false)

  const [filteredCollection, setFilteredCollection] =
    React.useState<T[]>(emptyArray)

  const filterList = React.useCallback(
    throttle((adGroups: ID[]) => {
      setInProgress(true)

      if (!adGroups.length || !collection?.length)
        setFilteredCollection(emptyArray)
      else {
        const capmSet = new Set(adGroups.map((it) => it?.toString()))
        const res = collection.filter((it) => {
          const adGroupId =
            ("AdgroupId" in it ? it?.AdgroupId : it?.AdGroupId)?.toString() ??
            "0"

          return capmSet.has(adGroupId)
        })
        setFilteredCollection(res)
      }

      setInProgress(false)
    }, 1_000),
    [collection]
  )

  React.useEffect(() => {
    setInProgress(true)
    filterList(adGroups)
  }, [filterList, adGroups])

  return { filteredCollection, inProgress }
}

export default useFilteredAdGroupChild
